var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("v-container", { staticClass: "d-flex justify-center mb-6" }, [
        _c(
          "div",
          { staticClass: "pt-12" },
          [
            _c(
              "v-form",
              {
                ref: "form",
                attrs: { "lazy-validation": "" },
                model: {
                  value: _vm.valid,
                  callback: function ($$v) {
                    _vm.valid = $$v
                  },
                  expression: "valid",
                },
              },
              [
                _c(
                  "v-card",
                  { staticClass: "no-border" },
                  [
                    _c("v-card-title", { staticClass: "pb-0" }, [
                      _c("div", { staticClass: "text-center pb-16" }, [
                        _c("img", {
                          staticClass: "hidden-xs-only pt-9",
                          attrs: {
                            src: require("@/assets/logo-cor-primaria.png"),
                            width: "148",
                          },
                        }),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "v-card",
                  { staticClass: "no-border", staticStyle: { width: "340px" } },
                  [
                    _c(
                      "v-card-title",
                      { staticClass: "pb-0 pt-0 title-login-flow" },
                      [_vm._v(" Redefinir senha ")]
                    ),
                    _c("v-card-title", { staticClass: "body-login-flow" }, [
                      _vm._v(
                        " Enviaremos um e-mail com instruções de como redefinir sua senha. "
                      ),
                    ]),
                    _c(
                      "v-card-text",
                      { staticClass: "px-4 pb-2 pt-0" },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "Email",
                            dense: "",
                            rounded: "",
                            outlined: "",
                            "persistent-placeholder": "",
                            placeholder: "Digite seu e-mail",
                            "error-messages": _vm.mailMessage,
                            rules: [_vm.rules.emailValidator],
                          },
                          model: {
                            value: _vm.email,
                            callback: function ($$v) {
                              _vm.email = $$v
                            },
                            expression: "email",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      {
                        staticStyle: {
                          "padding-top": "0px !important",
                          "padding-bottom": "32px !important",
                        },
                      },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: { disabled: !_vm.valid, color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.existsEmailAndSendToken()
                              },
                            },
                          },
                          [_vm._v(" Enviar instruções ")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      {
                        staticStyle: {
                          "padding-top": "0px !important",
                          "padding-bottom": "0px !important",
                        },
                      },
                      [_c("v-divider")],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticStyle: { "padding-top": "32px !important" } },
                      [
                        _c(
                          "v-btn",
                          {
                            staticStyle: {
                              "text-transform": "none",
                              color: "#2d5796",
                            },
                            attrs: { text: "" },
                            on: {
                              click: function ($event) {
                                return _vm.cancel()
                              },
                            },
                          },
                          [_vm._v(" Voltar ao login ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }