<template>
  <v-app>
    <v-container class="d-flex justify-center mb-6">
      <div class="pt-12">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card class="no-border">
            <v-card-title class="pb-0">
              <div class="text-center pb-16">
                <img :src="require('@/assets/logo-cor-primaria.png')" width="148" class="hidden-xs-only pt-9" />
              </div>
            </v-card-title>
          </v-card>
          <v-card style="width: 340px" class="no-border">
            <v-card-title class="pb-0 pt-0 title-login-flow"> Redefinir senha </v-card-title>
            <v-card-title class="body-login-flow">
              Enviaremos um e-mail com instruções de como redefinir sua senha.
            </v-card-title>

            <v-card-text class="px-4 pb-2 pt-0">
              <v-text-field
                v-model="email"
                label="Email"
                dense
                rounded
                outlined
                persistent-placeholder
                placeholder="Digite seu e-mail"
                :error-messages="mailMessage"
                :rules="[rules.emailValidator]"
              />
            </v-card-text>

            <v-card-actions style="padding-top: 0px !important; padding-bottom: 32px !important">
              <v-btn :disabled="!valid" color="primary" @click="existsEmailAndSendToken()"> Enviar instruções </v-btn>
            </v-card-actions>
            <v-card-actions style="padding-top: 0px !important; padding-bottom: 0px !important">
              <v-divider></v-divider>
            </v-card-actions>
            <v-card-actions style="padding-top: 32px !important">
              <v-btn style="text-transform: none; color: #2d5796" text @click="cancel()"> Voltar ao login </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import notificationServices from '@/services/notificationServices.js';
import userService from '@/services/userService.js';
import { eventBus } from '../../../../main.js';

export default {
  name: 'ModalForgotPasswordEmail',

  data: () => ({
    valid: false,
    show: false,
    email: '',
    hasError: false,
    errorMessage: '',
    mailMessage: '',
    sendingMail: '',
    hasSendingMail: false,
    rules: {
      emailValidator: (v) =>
        /^[a-zA-Z].*(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v,
        ) || 'Endereço de e-mail inválido',
      existingMail: () => true || this.mailMessage,
    },
  }),

  created() {
    this.init();
  },

  methods: {
    init() {
      if (localStorage.getItem('linkexpiredconfirmemail')) {
        this.showToast('Link expirado. Preencha abaixo para ser enviado um novo acesso.', 'error', 14);
        localStorage.removeItem('linkexpiredconfirmemail');
      } else {
        eventBus.$emit('closeToast');
      }
    },

    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },

    async existsEmailAndSendToken() {
      await this.$refs.form.validate();
      if (!this.valid) return;
      try {
        this.hasSendingMail = true;
        this.sendingMail = '';
        var isRegistration = false;
        this.showToast(
          'Caso esse e-mail esteja cadastrado, você receberá um e-mail com as instruções para recuperação da senha.',
          'success',
          14,
        );

        await notificationServices.existsEmailAndSendToken(this.email, isRegistration);
        await new Promise((r) => setTimeout(r, 1500));

        this.hasSendingMail = false;
        this.errorMessage = '';
        this.$router.push('/login');
      } catch (error) {
        this.errorMessage = error.response.data.erros[0];
      }
    },

    existsEmail() {
      userService
        .existsEmail(this.email)
        .then((response) => {
          if (response.data === false) {
            this.mailMessage = 'Endereço de e-mail inválido';
            this.rules.existingMail = false;
          } else {
            this.mailMessage = '';
            this.rules.existingMail = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancel() {
      this.$router.push(`/login`);
    },
  },
};
</script>
<style scoped>
.no-border {
  box-shadow: none !important;
  border: 0px solid #e7e7fa !important;
}
</style>
